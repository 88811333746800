<template>
    <div class="home_box">
        <div class="o_info_top">
            <div class="o_info_top_1">
                <el-breadcrumb separator-class="el-icon-arrow-right" v-if="form_type=='candidate'">
                    <el-breadcrumb-item :to="{ path: '/candidate' }">{{$t('main.text7')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/candidate_info',query:{userid:h_userid} }">{{$t('main.text48')}}</el-breadcrumb-item>
                    <el-breadcrumb-item><span class="o_info_top">{{$t('main.text49')}}</span></el-breadcrumb-item>
                </el-breadcrumb>
                <el-breadcrumb separator-class="el-icon-arrow-right" v-else-if="form_type=='order'">
                    <el-breadcrumb-item :to="{ path: '/' }">{{$t('main.text50')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/work_order_info',query:{order_id:h_order_id} }">{{$t('main.text51')}}</el-breadcrumb-item>
                    <el-breadcrumb-item><span class="o_info_top">{{$t('main.text49')}}</span></el-breadcrumb-item>
                </el-breadcrumb>
                <div v-else><i class="el-icon-arrow-left order_return" @click="$router.go(-1)"></i></div>
            </div>
            <div class="o_info_top2_1">
                <div>
                    <div class="o_info_top2_2">{{$t('main.text52')}}</div>
                    <div class="o_info_top2_3">{{top_data.order_id}}</div>
                </div>
                <div>
                    <div class="o_info_top2_2">{{$t('main.text53')}}</div>
                    <div class="o_info_top2_3">{{time_format(top_data.c_time)}}</div>
                </div>
                <div>
                    <div class="o_info_top2_2">{{$t('main.text54')}}</div>
                    <div class="o_info_top2_3">
                        <!-- "schedule_status": 1, //状态 1 处理中; 2 面试官未回复面试时间; 5 候选人联系中; 6 候选人联系中; 7 候选人要求调整待面试官确认; 
                        8 候选人放弃; 10 候选人联系方式错误; 11 面试安排暂停; 15 候选人联系中; 20 面试取消; 25 候选人放弃; 26 面试取消; 
                        30 候选人要求调整待面试官确认; 31 候选人要求调整待面试官确认; 32 面试取消; 33 待面试官确认; 35 面试已安排; 
                        36 面试改期; 37 候选人放弃; 40 面试已完成; 45 候选人爽约; 50 面试安排终止 -->
                        <span v-if="top_data.schedule_status==1"><span class="type4"></span>{{$t('main.text55')}}</span>
                        <span v-else-if="top_data.schedule_status==2"><span class="type4"></span>{{$t('main.text75')}}</span>
                        <span v-else-if="top_data.schedule_status==5"><span class="type4"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="top_data.schedule_status==6"><span class="type4"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="top_data.schedule_status==7"><span class="type4"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="top_data.schedule_status==8"><span class="type4"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="top_data.schedule_status==10"><span class="type4"></span>{{$t('main.text60')}}</span>
                        <span v-else-if="top_data.schedule_status==11"><span class="type4"></span>{{$t('main.text61')}}</span>
                        <span v-else-if="top_data.schedule_status==15"><span class="type4"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="top_data.schedule_status==20"><span class="type4"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="top_data.schedule_status==25"><span class="type4"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="top_data.schedule_status==26"><span class="type4"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="top_data.schedule_status==30"><span class="type4"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="top_data.schedule_status==31"><span class="type4"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="top_data.schedule_status==32"><span class="type4"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="top_data.schedule_status==33"><span class="type4"></span>{{$t('main.text63')}}</span>
                        <span v-else-if="top_data.schedule_status==35"><span class="type4"></span>{{$t('main.text64')}}</span>
                        <span v-else-if="top_data.schedule_status==36"><span class="type4"></span>{{$t('main.text65')}}</span>
                        <span v-else-if="top_data.schedule_status==37"><span class="type4"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="top_data.schedule_status==40"><span class="type4"></span>{{$t('main.text66')}}</span>
                        <span v-else-if="top_data.schedule_status==45"><span class="type4"></span>{{$t('main.text67')}}</span>
                        <span v-else-if="top_data.schedule_status==50"><span class="type4"></span>{{$t('main.text68')}}</span>
                        <span v-else>-</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="h_table">
            <div class="h_table_top"> 
                <div class="t_top_1">
                    {{$t('main.text69')}}
                </div>
            </div>
            <el-table
            :data="tableData"
            :cell-style="{textAlign:'center',color:'#333'}"
            :header-cell-style="{textAlign:'center',background:'#ECF3FD',color:'#838383'}"
            style="width: 100%">
                <el-table-column :label="$t('main.text70')"
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column :label="$t('main.text71')">
                    <span slot-scope="props">
                        {{ props.row.call_id }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text72')">
                    <span slot-scope="props">
                        {{ time_format(props.row.c_time) }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text73')">
                    <span slot-scope="props">
                        <!-- "schedule_status": 1, //状态 1 处理中; 2 面试官未回复面试时间; 5 候选人联系中; 6 候选人联系中; 7 候选人要求调整待面试官确认; 
                        8 候选人放弃; 10 候选人联系方式错误; 11 面试安排暂停; 15 候选人联系中; 20 面试取消; 25 候选人放弃; 26 面试取消; 
                        30 候选人要求调整待面试官确认; 31 候选人要求调整待面试官确认; 32 面试取消; 33 待面试官确认; 35 面试已安排; 
                        36 面试改期; 37 候选人放弃; 40 面试已完成; 45 候选人爽约; 50 面试安排终止 -->
                        <span v-if="props.row.schedule_status==1"><span class="dot1"></span>{{$t('main.text55')}}</span>
                        <span v-else-if="props.row.schedule_status==2"><span class="dot1 dot_bgc2"></span>{{$t('main.text75')}}</span>
                        <span v-else-if="props.row.schedule_status==5"><span class="dot1 dot_bgc2"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="props.row.schedule_status==6"><span class="dot1 dot_bgc2"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="props.row.schedule_status==7"><span class="dot1 dot_bgc2"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="props.row.schedule_status==8"><span class="dot1 dot_bgc2"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="props.row.schedule_status==10"><span class="dot1 dot_bgc2"></span>{{$t('main.text60')}}</span>
                        <span v-else-if="props.row.schedule_status==11"><span class="dot1 dot_bgc2"></span>{{$t('main.text61')}}</span>
                        <span v-else-if="props.row.schedule_status==15"><span class="dot1 dot_bgc2"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="props.row.schedule_status==20"><span class="dot1 dot_bgc2"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="props.row.schedule_status==25"><span class="dot1 dot_bgc2"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="props.row.schedule_status==26"><span class="dot1 dot_bgc2"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="props.row.schedule_status==30"><span class="dot1 dot_bgc2"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="props.row.schedule_status==31"><span class="dot1 dot_bgc2"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="props.row.schedule_status==32"><span class="dot1 dot_bgc2"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="props.row.schedule_status==33"><span class="dot1 dot_bgc2"></span>{{$t('main.text63')}}</span>
                        <span v-else-if="props.row.schedule_status==35"><span class="dot1 dot_bgc2"></span>{{$t('main.text64')}}</span>
                        <span v-else-if="props.row.schedule_status==36"><span class="dot1 dot_bgc2"></span>{{$t('main.text65')}}</span>
                        <span v-else-if="props.row.schedule_status==37"><span class="dot1 dot_bgc2"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="props.row.schedule_status==40"><span class="dot1 dot_bgc2"></span>{{$t('main.text66')}}</span>
                        <span v-else-if="props.row.schedule_status==45"><span class="dot1 dot_bgc2"></span>{{$t('main.text67')}}</span>
                        <span v-else-if="props.row.schedule_status==50"><span class="dot1 dot_bgc2"></span>{{$t('main.text68')}}</span>
                        <span v-else>-</span>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text76')">
                    <span slot-scope="props">
                        <span v-if="props.row.is_recuter_change_time==0">{{$t('main.text77')}}</span>
                        <span v-else-if="props.row.process_status==1">{{$t('main.text78')}}</span>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text79')">
                    <span slot-scope="props">
                        <span v-if="props.row.process_status==1">{{$t('main.text80')}}</span>
                        <span v-else-if="props.row.process_status==2">{{$t('main.text81')}}</span>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text82')">
                    <span slot-scope="props">
                        <span class="session_details" v-if="props.row.history&&props.row.history[0]&&props.row.history[0].msg" @click="open_session_details_dlg(props.row.history)">
                            <!-- <span class="session_details_txt">{{ (props.row.history[0].msg).substr(0, 5) }}</span> -->
                            <span class="session_details_txt">{{ props.row.history[0].msg }}</span>
                            <span class="details"> {{$t('main.text83')}}</span>
                            <!-- <i class="el-icon-caret-bottom"></i> -->
                        </span>
                    </span>
                </el-table-column>

            </el-table>

            <el-pagination
                class="pagination_box"
                v-if="total"
                background
                @size-change="size_change"
                @current-change="current_change"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>

        </div>

        <!-- 对话详情 弹框 -->
        <el-dialog
            title="$t('main.text84')"
            :visible.sync="session_details_dlg"
            width="600px"
            top="20%"
            center>
            <div class="session_d_body">
                <div v-for="(item,index) in history" :key="index">{{item.type}}:{{item.msg}}</div>
                <!-- <div v-for="index in 30" :key="index">A1:XXXXXX</div> -->
            </div>
        </el-dialog>

    </div>
</template>
    
<script>
import {call_list} from '@/api/api';
export default{
    data() {
        return {
            currentPage: 1, //选中的页数
            page: 1, //分页 从 1 开始
            limit: 10, //每页条数
            total: 0, //一共多少条
            tableData: [],
            session_details_dlg:false,
            time:'',

            h_order_id:this.$route.query.h_order_id||'',
            h_userid:this.$route.query.h_userid||'',
            top_data:{},
            history:[],
            form_type:this.$route.query.form_type||'',
        }
    },
    mounted() {
        this.get_list();
    },
    methods: {
        current_change(v) {
            this.page = v;
            this.limit = 10;
            this.get_list();
        },
        size_change(v) {
            this.limit = v;
            this.get_list();
        },
        async get_list(){
            const res = await call_list({
                order_id:this.h_order_id,
                userid:this.h_userid,
                page:this.page,
                limit:this.limit,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.top_data = res.data.data;
            this.tableData = res.data.data.list;
            this.total = res.data.data.countAll;
        },
        open_session_details_dlg(history){
            this.history = history;
            this.session_details_dlg = true;
        }
    },

}
</script>
    
<style scoped>
.h_table{
    border-radius: 6px;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

.h_table_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    font-size: 16px;
    margin-bottom: 12px;
}
.t_top_Right>div{
    display: inline-block;
}
.t_top_2{
    max-width: 160px;
    margin-right: 20px;
}
.t_top_2 >>> .el-input__inner,
.t_top_3 >>> .el-input__inner{
    border-color: #A9A9A9;
    color: #BCBCBC;
}
.t_top_3{
    max-width: 200px;
    margin-right: 30px;
    position: relative;
}
.t_top_3 i{
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #BCBCBC;
    font-size: 20px;
}
.t_top_3 >>> .el-input__inner{
    text-indent: 2em;
}
.t_top_3 >>> .el-input.is-active .el-input__inner,
.t_top_3 >>> .el-input__inner:focus {
    border-color: #409EFF;
}
/* ---- */
.o_info_top_1{
    margin-bottom: 30px;
}
.o_info_top2_1{
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 25px 20px;
    text-align: center;
    border-radius: 6px;
    background: #FFF;
    margin-bottom: 30px;
}
.o_info_top2_2{
    color: #A9A9A9;
    font-size: 16px;
    margin-bottom: 20px;
}
.o_info_top2_3{
    color: #333;
    font-size: 20px;
}
.t_top_1 span{
    color: #838383;
    font-size: 12px;
}
.session_details{
    /* display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; */
}
.session_details_txt{
    /* display: inline-block;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
}
.session_details i{
    margin-left: 10px;
}
.session_d_body{
    border-radius: 3px;
    background: #F6F7FB;
    box-sizing: border-box;
    padding: 15px;
    color: #333;
    font-size: 14px;
    line-height: 24px;
    max-height: 400px;
    overflow-y: auto;
}
.session_d_body::-webkit-scrollbar {
  width: 5px;
}
.session_d_body::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 10px;
}
.order_return{
    /* margin-bottom: 10px; */
    cursor: pointer;
}
</style>
